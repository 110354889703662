import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Radio, FormLabel, RadioGroup, FormControl, FormHelperText, FormControlLabel, } from '@mui/material';
export default function RHFRadioGroup({ row, name, label, options, spacing, helperText, ...other }) {
    const { control } = useFormContext();
    const labelledby = label ? `${name}-${label}` : '';
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", id: labelledby, sx: { typography: 'body2' }, children: label })), _jsx(RadioGroup, { ...field, "aria-labelledby": labelledby, row: row, ...other, children: options.map((option) => (_jsx(FormControlLabel, { value: option.value, control: _jsx(Radio, {}), label: option.label, sx: {
                            '&:not(:last-of-type)': {
                                mb: spacing || 0,
                            },
                            ...(row && {
                                mr: 0,
                                '&:not(:last-of-type)': {
                                    mr: spacing || 2,
                                },
                            }),
                        } }, option.value))) }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, sx: { mx: 0 }, children: error ? error?.message : helperText }))] })) }));
}
